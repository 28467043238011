/* eslint no-extend-native : 0 */
/* eslint func-names:0 */
/* eslint no-param-reassign:0 */
/* eslint no-plusplus:0 */
/**
 * @todo create a new plugin to manage all method and function
 * which has been added on native classes
 */
import kebabCase from 'lodash/kebabCase';

const TheAgent = {};
// eslint-disable-next-line no-unused-vars
TheAgent.install = function (Vue, options = {}) {
  /**
   * Lodash has kebabCase function, which follow almost
   * the same rules as slugify, except it ignores & symbol
   * @return {[type]} [description]
   * @see theagent-node-pim app/utils/common.js
   */
  if (typeof String.prototype.slugify !== 'function') {
    String.prototype.slugify = function slugify() {
      return kebabCase(this.replace(/&/g, '-'));
    };
  }

  /**
   * this function for String Object remove all special caracters
   *
   * @return {[type]} [description]
   * @see theanget-pim helpers/cleaner.js
   */
  if (typeof String.prototype.colorize !== 'function') {
    String.prototype.colorize = function colorize() {
      return this.replace(/[0-9]/g, '')
        .trim()
        .slugify();
    };
  }

  /**
   * Levenshtein distance between two given strings implemented
   * in JavaScript and usable as a Node.js module.
   * Compute the edit distance between the two given strings
   *
   * @param  {string} string [description]
   * @return {[type]}        [description]
   *
   * @see https://gist.github.com/andrei-m/982927
   * @see theagent-node-pim app/utils/common.js
   */
  if (typeof String.prototype.levenstein !== 'function') {
    String.prototype.levenstein = function levenstein(string) {
      const a = this;
      const b = `${string}`;
      const m = [];
      const { min } = Math;
      let i;
      let j;

      if (!(a && b)) return (b || a).length;

      // increment along the first column of each row
      for (i = 0; i <= b.length; m[i] = [i++]);
      // increment each column in the first row
      for (j = 0; j <= a.length; m[0][j] = j++);

      // Fill in the rest of the matrix
      for (i = 1; i <= b.length; i++) {
        for (j = 1; j <= a.length; j++) {
          m[i][j] = b.charAt(i - 1) === a.charAt(j - 1)
            ? m[i - 1][j - 1]
            : (m[i][j] = min(m[i - 1][j - 1] + 1, min(m[i][j - 1] + 1, m[i - 1][j] + 1)));
        }
      }

      return m[b.length][a.length];
    };
  }

  /**
   * this function for String Object remove all special caracters
   *
   * @return {[type]} [description]
   * @see theagent-node-pim app/utils/common.js
   */
  if (typeof String.prototype.sanitize !== 'function') {
    String.prototype.sanitize = function sanitize() {
      return this.replace(/("|(\s)+|\n|\r\n)/g, ' ').trim();
    };
  }

  /**
   * Check if String is Numeric
   */
  if (typeof String.isNumeric !== 'function') {
    String.isNumeric = function isNumeric(n) {
      // eslint-disable-next-line no-restricted-globals
      return !isNaN(parseFloat(n)) && isFinite(n);
    };
  }

  /**
   * Check if String is an ean
   */
  if (typeof String.isEan !== 'function') {
    String.isEan = function isEan(n) {
      return String.isNumeric(n) && n.length === 13;
    };
  }

  /**
   * To capitalize a string to make the first character uppercase
   * @return {String} [description]
   */
  if (typeof String.prototype.capitalize !== 'function') {
    String.prototype.capitalize = function capitalize() {
      return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
    };
  }

  /**
   * Swap key with value
   * @return {Object}        [description]
   */
  if (typeof Object.swap !== 'function') {
    Object.swap = function swap(entries) {
      return Object.entries(entries).reduce((ret, entry) => {
        const [key, value] = entry;
        ret[value] = key;
        return ret;
      }, {});
    };
  }

  /**
   * Swap key with value
   * @return {Object}        [description]
   */
  if (typeof Array.uniq !== 'function') {
    Array.uniq = function uniq(values) {
      // ES6 has a native object Set to store unique values
      return [...new Set(values)];
    };
  }
  if (typeof Array.prototype.uniq !== 'function') {
    Array.prototype.uniq = function uniq() {
      // ES6 has a native object Set to store unique values
      return Array.uniq(this);
    };
  }

  if (typeof String.prototype.eof !== 'function') {
    String.prototype.eof = function eof() {
      return this.replace(/<br \/>/gi, '\n')
        .replace(/(\r\n|\\r\\n)/g, '\n')
        .replace(/\n\n+/g, '\n')
        .trim();
    };
  }

  if (typeof String.prototype.striptags !== 'function') {
    String.prototype.striptags = function striptags() {
      return this.eof()
        .replace(/(<([^>]+)>)/gi, '')
        .trim();
    };
  }

  if (typeof String.normalizeQuotes !== 'function') {
    String.normalizeQuotes = function (str) {
      return str
        .replace(/[‘’\u2019\u2018\u201B]/g, "'") // Replace curly quotes (‘ ’ ‛)
        // eslint-disable-next-line no-control-regex
        .replace(/[\u0019\x19]|_x0019_/g, "'") // Replace control character EM (rare) and XML-escaped EM
        .replace(/[“”]/g, '"')
        .replace(/[\u2013\u2014]/g, '-')
        .replace(/[\u2026]/g, '...');
    };
  }
};
export default TheAgent;
